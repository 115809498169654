import React from "react";
import { Link } from "gatsby";
import {
  PROJECT_DEV_2021_DATES as dates,
  // PROJECT_DEV_2021,
} from "src/helpers/config";
import { isGTNow } from "src/helpers/datetime";

const Schedule = ({ location }) => {
  const { pathname } = location || {};

  const isCurrentPath = path => pathname.startsWith(path);

  return (
    <div className="schedule">
      <div className="videos">
        <ul className="wrapper">
          {dates.map(d => {
            const active = isGTNow(d.start) ? "active" : "disabled";
            const current = isCurrentPath(d.relative) ? "current" : "";

            const styles = [active, current].join(" ");
            return (
              <li key={d.key} className={styles}>
                <Link to={isGTNow(d.start) ? d.relative : pathname}>
                  <time
                    className="date"
                    dateTime={`${d.day}/${d.month}`}
                  >{`${d.day}/${d.month}`}</time>
                </Link>
              </li>
            );
          })}
        </ul>
        {/* eslint-disable-next-line */}
        <marquee>
          {/* eslint-disable-next-line */}
          {/* {`#${PROJECT_DEV_2021}${"\u00A0".repeat(16)}`.repeat(4)} */}
        </marquee>
      </div>
    </div>
  );
};

export default Schedule;
