import React from "react";
import Youtube from "emersonbroga-youtube-embed";
import { FacebookProvider, Comments } from "react-facebook";

import SEO from "src/components/SEO";
import { NAME, BASE_URL, FACEBOOK_APP_ID } from "src/helpers/config";
// import { redirectTo } from "src/helpers/route";

import Header from "../components/Header";

import Schedule from "../components/Schedule";

import { PROJECT_DEV_2021_DATES as dates } from "src/helpers/config";
import { isGTNow } from "src/helpers/datetime";

import "../projeto-dev-2021.scss";

const Day1 = ({ location }) => {
  const seoProps = {
    title: `Projeto Dev 2021 - Dia 1 - ${NAME}`,
    description:
      "Começou o primeiro dia do Projeto Dev 2021, não deixe de participar.",
    canonical: `${BASE_URL}/dev-2021/day-1/`,
    image: `${BASE_URL}/meta/og-image-projeto-dev-2021.jpg`,
  };

  // redirectTo("/react");

  if (!isGTNow(dates[0].start)) {
    return (
      <div className="projeto-dev-2021">
        <SEO {...seoProps} />
        <Header />
        <Schedule location={location} />
        <div className="wrapper">
          <div className="day1">
            <h2>Aguarde...</h2>
            <p>Esse video será liberado no dia 25 de Janeiro às 8:00h</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="projeto-dev-2021">
      <SEO {...seoProps} />
      <Header />
      <Schedule location={location} />
      <div className="wrapper">
        <div className="day1">
          <h2>Se torne um Dev em 2021</h2>
          <Youtube videoId="a2M88DPaNr4" />
        </div>
      </div>

      <div className="facebook wrapper">
        <p>
          Se você quer se tornar um dev em 2021,deixe nos comentários, "Eu quero
          me tornar um Dev em 2021"
        </p>
        <FacebookProvider appId={FACEBOOK_APP_ID}>
          <Comments href={`${BASE_URL}/dev-2021/day-1/`} width="100%" />
        </FacebookProvider>
      </div>
    </div>
  );
};
export default Day1;
