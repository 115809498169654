// export const secondsToTime = sec => {
//   const format = v => `0${v}`.slice(-2);
//   const hours = format(Math.floor(sec / 3600));
//   const minutes = format(Math.floor(sec / 60) % 60);
//   const seconds = format(sec % 60);
//   if (hours === "00") return `${minutes}:${seconds}`;
//   return `${hours}:${minutes}:${seconds}`;
// };

export const secondsToTime = total => {
  const hours = Math.floor(total / 3600);
  const minutes = Math.floor((total % 3600) / 60);
  const seconds = Math.round(total % 60);

  const data = hours ? [hours, minutes, seconds] : [minutes, seconds];

  return data.map(v => `0${v}`.slice(-2)).join(":");
};

export const isGTNow = date => {
  const now = new Date().getTime();
  const start = new Date(date).getTime();
  return now > start;
};
