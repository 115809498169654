import React from "react";
import { Link } from "gatsby";
import { PROJECT_DEV_2021 } from "src/helpers/config";

const Header = () => {
  return (
    <div className="header">
      <Link to="/dev-2021/day-1/">
        <img
          loading="lazy"
          src="/images/logo-dev-2021-black.png"
          alt={PROJECT_DEV_2021}
        />
      </Link>
    </div>
  );
};
export default Header;
